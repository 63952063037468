'use client';

import Link from 'next/link';
import { useParams } from 'next/navigation';
import Cta from 'shared/components/CtaBtn';
import { getI18nUtils } from '../i18n/i18n-utils';

function rotateSvg(e) {
  const svg: any = document.querySelector('.error-page-compass');
  const svgOffset = document.querySelector('.error-page-compass')?.getBoundingClientRect();
  const svgX = svgOffset ? svgOffset.left + svgOffset.width / 2 : 0;
  const svgY = svgOffset ? svgOffset.top + svgOffset.height / 2 : 0;
  const degree = Math.atan2(e.pageX - svgX, -(e.pageY - svgY)) * (180 / Math.PI);
  svg.style.transform = `rotate(${degree - 45}deg)`;
}

const Custom404Page = ({ dictionary }) => {
  const { locale } = useParams();
  const { formatMessage } = getI18nUtils(dictionary);

  return (
    <div onMouseMove={(e) => rotateSvg(e)} className="container">
      <div className="flex-container">
        <div>
          <h1 className="error-page-title ms-2">{formatMessage({ id: 'page-not-found' })}</h1>
          <p className="error-page-text ms-2">{formatMessage({ id: 'error-message' })}</p>
          <div className="error-page-404">
            <p className="error-text-404 me-4 mt-2">4</p>
            <svg className="error-page-compass" xmlns="http://www.w3.org/2000/svg" width="115" height="115" viewBox="0 0 115 115">
              <path
                id="Union_23"
                data-name="Union 23"
                d="M575.5,902A57.5,57.5,0,1,0,633,959.5,57.5,57.5,0,0,0,575.5,902Zm0,103.725A46.225,46.225,0,1,1,621.725,959.5,46.225,46.225,0,0,1,575.5,1005.725Zm-7.944-54.169L550.531,980.5l3.973,3.971,28.939-17.025L600.469,938.5l-3.973-3.973Zm7.944,13.863a5.919,5.919,0,1,1,5.919-5.919A5.918,5.918,0,0,1,575.5,965.419Z"
                transform="translate(-518 -902)"
                fill="#222e50"
              />
            </svg>
            <p className="error-text-404 ms-4 mt-2">4</p>
          </div>
          <div className="error-page-links">
            <Link href={`/${locale}/jobs/`}>
              <Cta className="cta error-cta red" type="button">
                {formatMessage({ id: 'error-page-joboffer' })}
              </Cta>
            </Link>
            {process.env.NEXT_PUBLIC_APP !== 'werkenbijsynergie.be' && process.env.NEXT_PUBLIC_APP !== 'sandyou.be' && (
              <Link href={`/${locale}/agencies`}>
                <Cta className="cta error-cta red" type="button">
                  {formatMessage({ id: 'error-page-agency' })}
                </Cta>
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Custom404Page;
